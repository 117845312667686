.sidePanelContainer {
    left: 0;
    height: 100%;
    z-index: 50;
    transition: transform 0.3s ease;
    background-color: white;
    width: 56px;
}

.sidePanelMenu {
    width: 56px;
    top: 0;
    z-index: 51;
}

.sidePanelWrapper {
    display: block;
}
